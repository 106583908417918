import { Link } from 'react-router-dom';
import './index.scss';

const Footer = () => {
  return (
    <div className="footer-wrap">
      <footer className="footer">
        <div className="main-content">
          <div className="footer_call">
            <i className="iconfont icon-24gf-telephone2"></i>
            <div className="footer_call-info">
              <div className="footer_call-info-txt">咨询热线</div>
              <div className="footer_call-info-phone"></div>
            </div>
          </div>
          <div className="footer_copy">公司名称：欢动在线（上海）网络科技有限公司</div>
          <div className="footer_address">总部地址：中国（上海）自由贸易试验区亮景路232号701室</div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="main-content">
          <span className="footer-left_copy">Copyright © 2022-2023 </span>
          <a className="footer-left_mail" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            ICP备案号：沪ICP备2023003959号-6
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
